import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from 'weavix-shared/services/translation.service';

@Component({
    selector: 'app-network-coverage-detail',
    templateUrl: './network-coverage-detail.component.html',
    styleUrls: ['./network-coverage-detail.component.scss'],
})
export class NetworkCoverageDetailComponent implements OnInit {
    @Input() averages: { [key: string]: number } = {};
    @Input() metricsToDisplay: any[] = [];
    @Input() surveyCount: number = 0;
    constructor(private translationService: TranslationService) {
        this.title = this.translationService.getImmediate('configuration.facility-network-coverage.metric-averages-header');
    }
    title = 'Metric Averages';
    ngOnInit(): void {
    }
    componentDidMount(): void {
    }
}
